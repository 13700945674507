.button_container {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 100px;
  height: 60px;
}

.button_chat {
  display: flex;
  height: 50px;
  width: 50px;
  float: left;
  margin: 0 5px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #0052A3;
  align-items: center;
  justify-content: flex-end;
  transition-duration: 300ms;
  position: fixed;
  bottom: 30px;
  right: 30px;
}

.chat_img {
  filter:none;
  position: fixed;
  bottom: -12px;
  right: -5px;
  transition: none;
  pointer-events: none;
  scale: 23%;
}

.modalContent {
  background: white;
  border-radius: 10px;
  width: 270px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 100px;
  right: 40px;
  z-index: 99;
}

.modalHeader{
  background: linear-gradient(to bottom, #003366, #125190, #2877C5);
  color: white;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;  
}

.modalHeader h2{
  font-weight: 500;
  font-size: 14px;
}

.modalBody{
  padding: 20px;
  text-align: start;
  width: 270px;
  color: #003366;
  background: #F5F7F9;
  font-weight: 500;
  font-size: 13px;
  border-radius: 0 0 10px 10px;
}

.wa_click{
  display: flex;
  align-items: center;
  padding: 7px;
  background: white;
  margin-top: 30px;
  border-radius: 5px;
  justify-content: space-between;
}

.wa_desc {
  display:flex;
  align-items: center;
}

.modal_img {
  height: 60px;
  width: 60px;
  margin-right: 20px;
}

.wa_img {
  height: 31px;
  width: 31px;
  margin-right: 10px;
}

.send_img {
  height: 20px;
  width: 20px;
  margin-left: 10px;
}


@-moz-document url-prefix() {
  /** firefox only */
   .modalContent {
    width: 330px;
  }

  .modalBody{
    width: 330px;
  }
}

/** tab styles */

@media screen and (min-width: 992px) {
  .wa_contact {
    margin: 52px;
  }

  .button_chat {
    display: flex;
    height: 50px;
    width: 50px;
    float: left;
    margin: 0 5px;
    border-radius: 10px;
    cursor: pointer;
    background-color: #0052A3;
    align-items: center;
    justify-content: flex-end;
    transition-duration: 300ms;
    position: fixed;
    bottom: 30px;
    right: 30px;
  }

  .button_chat:hover {
    filter: blur(3px);
    box-shadow: 0 0 20px 5px rgba(26, 115, 232, 0.5);
    border-radius: 100px;
  }

  .modalContent {
    width: 330px;
  }

  .modalBody{
    width: 330px;
  }

  .modalHeader h2{
    font-weight: 550;
    font-size: 18px;
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      /* Safari Only CSS here */
       .modalContent {
          width: 330px;
        }

        .modalBody{
          width: 330px;
        }
    }
  }
  @-moz-document url-prefix() {
    /** firefox only css */
      .modalContent {
        width: 330px;
      }

      .modalBody{
        width: 330px;
      }
  }
}
