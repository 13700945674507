.main_activity_benefit_card {
  min-width: 0;
  max-width: 336px;
  width: 100%;
  /* min-height: 202px;
    max-height: auto;
    height: 100%; */
  height: 290px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #cfd9e2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* flex-wrap: wrap; */
  gap: 10px;
  padding: 24px;
  box-shadow: 1px 6px 20px 4px rgba(0, 51, 102, 0.009);
  box-sizing: border-box;
}
.main_activity_benefit_card:hover {
  background: linear-gradient(180deg, #003366 0%, #125190 50%, #2877c5 100%);
}
.main_activity_benefit_card:hover .main_activity_benefit_title,
.main_activity_benefit_card:hover .main_activity_benefit_desc {
  background: none;
  color: white;
  -webkit-text-fill-color: white;
}

/* benefit icon 1 */
.icon_bg_path_1 {
  fill: url(#paint0_linear_345_3431);
}

.icon_fg_path_1 {
  fill: url(#paint1_linear_345_3431);
}

.main_activity_benefit_card:hover .icon_bg_path_1 {
  fill: #e0ecf9 !important;
}

.main_activity_benefit_card:hover .icon_fg_path_1 {
  fill: white !important;
}

/* benefit icon 2 */
.icon_bg_path_2 {
  fill: url(#paint0_linear_159_1052);
}

.icon_fg_path_2 {
  fill: url(#paint1_linear_159_1052);
}

.main_activity_benefit_card:hover .icon_bg_path_2 {
  fill: #e0ecf9 !important;
}

.main_activity_benefit_card:hover .icon_fg_path_2 {
  fill: white !important;
}

/* benefit icon 3 */
.icon_bg_path_3 {
  fill: url(#paint0_linear_159_1056);
}

.icon_fg_path_3 {
  fill: url(#paint1_linear_159_1056);
}

.main_activity_benefit_card:hover .icon_bg_path_3 {
  fill: #e0ecf9 !important;
}

.main_activity_benefit_card:hover .icon_fg_path_3 {
  fill: white !important;
}

/* benefit icon 4 */
.icon_bg_path_4 {
  fill: url(#paint0_linear_159_1060);
}

.icon_fg_path_4 {
  fill: url(#paint1_linear_159_1060);
}

.main_activity_benefit_card:hover .icon_bg_path_4 {
  fill: #e0ecf9 !important;
}

.main_activity_benefit_card:hover .icon_fg_path_4 {
  fill: white !important;
}

.main_activity_benefit_icon {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.main_activity_benefit_icon_image {
  object-fit: cover;
}

.main_activity_benefit_title {
  font-family: 'Nunito Sans';
  text-align: start;
  font-weight: 800;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.15%;
  background: linear-gradient(180deg, #003366 0%, #2877c5 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease;
}
.main_activity_benefit_desc {
  text-align: start;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.13%;
  background: linear-gradient(180deg, #003366 0%, #2877c5 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease;
}

.default_icon {
  display: flex;
}

.hover_icon {
  display: none;
}

.main_activity_benefit_card:hover .default_icon {
  display: none;
}

.main_activity_benefit_card:hover .hover_icon {
  display: flex;
}

@media screen and (max-width: 768px) {
  .main_activity_benefit_card {
    padding: 16px;
    height: auto !important;
  }
  .main_activity_benefit_desc {
    width: auto;
    text-align: left;
  }
  .main_activity_benefit_icon {
    width: 40px;
    height: 40px;
  }
  .main_activity_benefit_title {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
  }
  .main_activity_benefit_desc {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.15%;
    letter-spacing: 0.004em;
  }
}

@media screen and (max-width: 923px) {
}
