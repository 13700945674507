.main_activity_detail {
    margin-top: var(--nav-height-min);
    height: 100%;
}

.banner_section {
    display: flex;
    position: static;
    background: linear-gradient(180deg, #00509F 3%, #002E5C 71.5%, #000000 100%);
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    /* min-height: 80vh; */
    padding-top: 40px;
    padding-bottom: 148px;
    max-height: auto;
    height: 100%;
    transition: all 0.3s ease;
}

.banner_section_wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap-reverse;
    align-items: center;
    gap: 40px;
    transition: all 0.3s ease;
    position: static;
}

.banner_left_content_wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    min-width: auto;
    max-width: 484px;
    width: 100%;
    transition: all 0.3s ease;
}

.banner_right_content_wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
}


.banner_title {
    font-size: 35px;
    font-weight: 800;
    line-height: 46px;
    letter-spacing: 0.5%;
    color: white;
    text-wrap: pretty;
    min-width: auto;
    max-width: 445px;
    width: 100%;
    transition: all 0.3s ease;
}

.banner_title_span {
    background-color: var(--secondary);
    color: #003366;
    font-size: 35px;
    font-weight: 800;
    line-height: 46px;
    letter-spacing: 0.5%;
    padding: 6px;
    transition: all 0.3s ease;
}

.banner_desc {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.25%;
    color: white;
    transition: all 0.3s ease;
}

.banner_button_wrapper {
    display: flex;
    flex-direction: row;
    gap: 24px;
    transition: all 0.3s ease;
}

.banner_explore_btn {
    display: flex;
    flex-direction: row;
    gap: 8px;
    min-width: auto;
    max-width: fit-content;
    width: 100%;
    /* height: 48px; */
    background-color: transparent; 
    border: 1px solid #b4c7e7;
    padding: 12px 24px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    color: white;
    text-decoration: none;
}

.banner_explore_btn_text {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 2%;
    text-align: center;
    text-decoration: none;
}

.banner_explore_btn:hover {
    background-color: transparent; 
    border: 1px solid var(--secondary);
    color: var(--secondary);
    text-decoration: none;
}

.icon_fg_caretDoubleDown {
    fill: white;  
    transition: all 0.3s ease;
}

.banner_explore_btn:hover .icon_fg_caretDoubleDown {
    fill: var(--secondary) !important;  
}

.banner_cta_btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    min-width: auto;
    max-width: fit-content;
    width: 100%;
    background-color: var(--secondary);
    border-radius: 6px;
    padding: 12px 24px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: none !important;
}

.banner_cta_btn_text {
    color: #003366;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 2%;
    text-align: center;
}

.banner_cta_btn:hover {
    background-color: var(--secondary-hover-1);
    border-color: var(--secondary-hover-2);
    text-decoration: none !important;
}

.responsive_icon {
    width: 20px;
    height: 20px;
    transition: all 0.3s ease;
    object-fit: cover;
}

.expert_section{ 
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
}

.expert_section{ 
    display: flex;
    justify-content: center;
    align-items: center;
}

.cts_section {
    /* padding-top: 60px; */
}

.cts_wrapper{
    display: flex;
    flex-direction: row;
    background: linear-gradient(        
        180deg,                            
        #003366 0%,                         
        #125190 50%,                      
        #2877C5 100%                        
    );
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding: 0px 20px;
}

.cts_left_content_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 0%;
    max-width: 558px;
    width: 100%;
    /* background: red; */
}

.cts_title {
    font-size: 24px;
    font-weight: 800;
    line-height: 36px;
    color: white;
}

.cts_subtitle {
    padding-top: 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: white; 
}

.button_wrapper {
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding-top: 24px;
}

.contact_button {
    min-width: auto;
    max-width: 178px;
    width: 100%;
    background-color: transparent; 
    color: white;
    border: 1px solid #b4c7e7;
    padding: 12px 24px;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.form_trial_btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    min-width: auto;
    max-width: 178px;
    width: 100%;
    background-color: var(--secondary);
    border-radius: 6px;
    padding: 12px 24px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: none;
}

.form_trial_btn_text {
    color: #003366;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 2%;
    text-align: center;
    text-decoration: none;
}

.form_trial_btn:hover {
    background-color: var(--secondary-hover-1);
    border-color: var(--secondary-hover-2);
    text-decoration: none !important;
}


.contact_button:hover {
    background-color: transparent;
    color: var(--secondary);
    border: 1px solid var(--secondary);
}


.cts_right_content_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0px;
}

.expert_section{
}

.benefit_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 60px;
    gap: 24px;
    margin-bottom: 60px;
}

.benefit_section_title {
    font-family: 'Nunito Sans';
    text-align: center;
    font-weight: 800;                    
    font-size: 24px;                      
    line-height: 36px;                    
    color: #003366; 
    transition: all 0.3s ease;
}

.benefit_section_content_wrapper{ 
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;
   
}

.client_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    padding-top: 60px;
}


.client_title { 
    text-align: center;
    font-weight: 800;                    
    font-size: 24px;                      
    line-height: 36px;                    
    color: #2B4156; 
}

.client_title_span {
    text-align: center;
    font-weight: 800;                    
    font-size: 24px;                      
    line-height: 36px;
    background: linear-gradient(180deg, #003366 0%, #125190 44.5%, #2877C5 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.client_content_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 60px
}

.client_logo_img {
    width: auto;
    height: 60px;
    object-fit: cover;
}

.sector_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 92px;
    gap: 30px;
}

.sector_section_title {
    text-align: center;
    font-weight: 700;                    
    font-size: 24px;                      
    line-height: 36px;
    color: #003366;
}

.counter_sector_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 30px 24px;
    flex-wrap: nowrap;
}

.counter_sector_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* background: red; */
    /* flex-wrap: wrap; */
    /* flex: 1 1 auto; */
    /* border-right: 3px solid #CFD9E2 */
    position: relative;
}

.counter_sector_item::after {
    content: '';
    position: absolute;
    right: 0; 
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 80px;
    background-color: #CFD9E2;
}

.counter_divider {
    height: 70px;
    border-right: 3px solid #CFD9E2
}

.counter_sector_item:last-of-type::after  {
    content: none;
}


.testimonial_section {
    /* margin-top: 60px; */
    background-color: #F5F7F9;
}

.solution_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 60px 0;
}

.solution_section_title {
    font-size: 24px;
    font-style: normal;
    line-height: 36px;
    font-weight: 700;
    letter-spacing: 0em;
    color: #003366;
}

.activity_card_container {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    max-width: 100%;
    justify-content: center;
    padding-top: 24px;
    margin: 0 auto;
}

.benefit_icon {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 480px) {
    .expert_section{
        padding: 0px 20px;
    }
    .benefit_section_content_wrapper {
        flex-direction: column; 
    }
    
    .counter_sector_wrapper {
        display: flex !important; 
        flex-direction: column;
        width: auto;
        flex-wrap: wrap;
        /* grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); */
        /* grid-template-columns: repeat(auto, minmax(200px, 1fr)); */
        /* background-color: red; */
    }

    .counter_sector_item {
        display: flex; 
        flex-direction: column !important;
        flex-wrap: wrap;
    }

    .counter_sector_item::after {
        content: '' !important;
        position: absolute;
        bottom: 0; 
        top: 100%;
        right: 50%;
        transform: translateY(-100%);
        transform: translateX(50%);
        width: 80px;
        height: 3px;
        background-color: #CFD9E2;
    }

    .counter_sector_item:last-of-type::after {
        content: none !important;
    }

    .sector_section_title {
        font-size: 18px;
        font-weight: 700;
        line-height: 26px;
        text-align: center;
        color: #003366;
    }
    
    
}



@media screen and (max-width: 528px) {

    .sector_section{
        padding: 0px 20px;
    }

}

@media screen and (max-width: 768px) {

    .expert_section, .sector_section{
        padding: 0px 20px;
    }
    .benefit_section_content_wrapper {
        flex-direction: column; 
    }

    .benefit_section_content_wrapper{
        padding: 0px 20px;
    }

    .cts_right_content_wrapper {
        display: none;
    }

    .button_wrapper {
        flex-direction: column-reverse;
        align-items: center;
        width: 100%;
    }
    .contact_button .button_form_trial{ 
        width: 145px;
        font-size: 12px;
        font-weight: 700;
        line-height: 24px;
    }

    .benefit_section_title {
        font-weight: 800;                    
        font-size: 18px;                      
        line-height: 26px;                    
        /* letter-spacing: 0.15%;      */
    }

    .banner_section_wrapper {
        gap: 32px;
    }
    
    .banner_left_content_wrapper {
        gap: 0px;
    }

    .banner_title {
        margin-bottom: 16px;
        font-size: 22px;
        font-weight: 700;
        line-height: 30px;
        color: white;
    }
    .banner_title_span {
        font-size: 22px;
        font-weight: 700;
        line-height: 30px;
        padding: 2px;
    }
    .banner_desc {
        margin-bottom: 32px;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
    }

    .banner_button_wrapper {
        flex-direction: column;
        gap: 24px;
        justify-content: center;
        align-items: center;
    }

    .banner_explore_btn { 
        /* width: 250px; */
        /* padding: ; */
        min-width: auto;
        max-width: 250px;
        width: 100%;
        height: 40px;
        padding-bottom: 8px;
        padding-top: 8px;
        text-overflow: ellipsis;
        /* padding: 12px 24px; */
    }

    .banner_explore_btn_text {
        font-size: 12px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 1.5%;
        text-align: center;
        text-overflow: ellipsis;
    }
    
    .banner_cta_btn {
        min-width: auto;
        max-width: 250px;
        width: 100%;
        height: 40px;
        padding-bottom: 8px;
        padding-top: 8px;
        text-overflow: ellipsis;
    }

    .banner_cta_btn_text {
        font-size: 12px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 1.5%;
        text-align: center;
        text-overflow: ellipsis;
    }
    .responsive_icon {
        width: 18px;
        height: 18px;
    }

}

@media screen and (max-width: 923px) {
    .expert_section{
        padding: 0px 20px;
    }

    .cts_wrapper {
        padding: 30px 20px;
    }

    .banner_section {
        height: fit-content;
    }

    .banner_section_wrapper {
        gap: 32px;
        padding: 40px 20px 78px 20px;
    }
    /* .counter_sector_wrapper {
        flex-wrap: wrap;
    }

    .counter_sector_item {
        flex: 1 1 50%;
        max-width: 50%; 
        box-sizing: border-box;
    }

    .counter_sector_item:nth-of-type(2n) .counter_divider {
        display: none;
    } */


    .counter_sector_wrapper {
        display: grid; 
        grid-template-columns: repeat(2, 1fr);
        width: auto;
    }

    .counter_sector_item {
        flex: unset; 
    }

    .counter_sector_item:nth-child(even)::after {
        content: none; 
    }

    .sector_section_title {
        font-size: 18px;
        font-weight: 700;
        line-height: 26px;
        text-align: center;
        color: #003366;
    }
    


    
}

@media screen and (max-width: 1200px) {
    .banner_section_wrapper {
        gap: 32px;
        padding: 40px 20px 78px 20px;
    }

    
    
    
}